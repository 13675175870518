import i18n from "@/i18n";

export const supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
const rtlLocales = ['fa']
export let isRtl;
export let dir;
export let lang;

let locale = location.pathname.split('/')[1];
if (supportedLocales.includes(locale)) {
    i18n.global.locale.value = locale;
    localStorage.setItem('language', locale)
    lang = locale;
} else {
    i18n.global.locale.value = 'fa';
    localStorage.setItem('language', 'fa')
    lang = 'fa';
}

isRtl = rtlLocales.includes(localStorage.getItem('language'))
isRtl ? dir = 'rtl' : dir = 'ltr';

document.documentElement.setAttribute("lang", lang);
document.documentElement.setAttribute("dir", dir);