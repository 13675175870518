export default {
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "faamSmartPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faam Smart Platform"])},
  "navBar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "what_faam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's Faam?"])},
    "investing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investing in Faam"])},
    "download_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download app"])},
    "benefit_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefit rate and history"])},
    "projects_colleagues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects and colleagues"])},
    "licences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faam Smart Platform"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Egestas purus viverra accumsan in nisl nisi"])}
  },
  "benefit": {
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefit predict"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Egestas purus viverra accumsan in nisl nisi Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque In egestas erat imperdiet sed euismod nisi porta lorem mollis Morbi tristique senectus et netus"])},
    "accuracy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6-month Performance Accuracy"])}
    }
  }
}