<template>
  <router-view/>
</template>

<script>
import i18n from "@/i18n";

export default {
  name: 'App',
  setup() {
    document.title = i18n.global.t('faamSmartPlatform')
  }
}
</script>

<style>

</style>
