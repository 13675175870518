export default {
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ورود"])},
  "faamSmartPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بستر هوشمند شتابدهی فام"])},
  "getApk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دریافت مستقیم"])},
  "getGooglePlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دریافت از گوگل پلی"])},
  "getMyket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دریافت از مایکت"])},
  "getGalaxyStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دریافت از گلکسی استور"])},
  "navBar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خانه"])},
    "what_faam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فام چیه؟"])},
    "investing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["همکاری با فام"])},
    "download_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دانلود اپ"])},
    "benefit_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نرخ سود و تاریخچه"])},
    "projects_colleagues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پروژه ها و همکاران"])},
    "licences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجوزها و تاییدیه ها"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تماس با ما"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بستر هوشمند شتابدهی فام"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فام پلتفرمی چندوجهی قدرت گرفته از هوش مصنوعی و یادگیری ماشین است که می تواند فرایند مشارکت و شتابدهی استارتاپ ها و پروژه های نرم افزاری و حوزه فناوری را ساده، کنترل و پیشبینی کند."])}
  },
  "benefit": {
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سالیانه"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماهیانه"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیشبینی سود"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درصد سود پیشبینی شده ی پلتفرم فام به صورت علی الحساب و توسط الگوریتم هوش مصنوعی انحصاری پلتفرم و با توجه به متغیر های گوناگونی اعم از بازدهی استارتاپ ها، تعداد سرمایه گذاران و میزان مشارکت های انجام شده، تغییرات هزینه و درآمد و ... به صورت خودکار محاسبه و در سایت و اپلیکیشن رسمی پلتفرم جهت اطلاع عموم بارگذاری می شود.\nاین تغییرات بیار زیاد و پیوسته در حال انجام هستند اما برای بهبود رابط کاربری یک بار در ماه میانگین قابل قبولی از این پیشبینی ها در سایت و اپلیکیشن رسمی این پلتفرم بارگذاری می شود."])},
    "accuracy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 ماه"])}
    }
  },
  "whatIsFaam": {
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فام پلتفرمی چندوجهی قدرت گرفته از هوش مصنوعی و یادگیری ماشین است که می تواند فرایند مشارکت و شتابدهی استارتاپ ها و پروژه های نرم افزاری و حوزه فناوری را ساده، کنترل و پیشبینی کند.\nدر پلتفرم هوشمند فام، علاقمندان به مشارکت در استارتاپ ها میتوانند بدون نیاز به دانش فنی نرم افزار یا آشنایی با بازار های سرمایه به سادگی روی پر بازده‌ترین  استارتاپ‌ها یا پروژه‌های نرم‌افزاری مشارکت و کسب درآمد کنند.\nهمچنین صاحبین ایده، افراد یا سازمان‌ها می‌توانند با استفاده از پلتفرم هوشمند فام علاوه بر جذب سرمایه، در صفر تا صد مسیر تجاری سازی و ساخت محصول نهایی از خدماتی چون منتورینگ و مشاوره تخصصی، تیم سازی، حمایت حقوقی و ثبت اثر، ارزش گذاری، معرفی به بازار های بین المللی و... بهره‌مند شوند."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هسته هوشمند پلتفرم فام در تمام مراحل ارتباط میان سرمایه گذار و سرمایه پذیر و همچنین روند طراحی ، ساخت و اصلاح یک کسب و کار نرم افزاری را مدیریت ، هدایت و نظارت میکند."])}
  },
  "investing": {
    "contentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پلتفرم فام یک پلتفرم چند وجهی هوشمند است که میتواند خدمات تخصصی خود را در اختیار گروه های زیر قرار دهد :"])},
    "item1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صاحبین ایده ، شرکت ها و سازمان ها :"])},
    "item1Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پلتفرم فام میتواند به صاحبین ایده یا شرکت ها و سازمان هایی که در موضوع فعالیت خود نیازمند خدمات نرم افزاری اعم از طراحی ، ساخت یا نگهداری سامانه های نرم افزاری هستند خدمات تامین مالی، تامین نیروی انسانی متخصص و راهکار های فنی و مشاوره تخصصی ارائه دهد."])},
    "item2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامه نویسان ، طراحان ، تولید کنندگان محتوا ، فعالان حوزه فناوری اطلاعات و نرم افزار :"])},
    "item2Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["با ثبت نام و ارائه رزومه و معرفی مهارت های خود میتوانند به پروژه های نرم افزاری در حال اجرا که نیازمند نیروی انسانی متخصص هستند معرفی و مشغول به کار شوند."])},
    "item3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سرمایه گذاران و علاقمندان (عموم افراد) :"])},
    "item3Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقریبا تمام افرادی که علاقمند به کسب درآمد از ساخت و توسعه پروژه های نرم افزاری هستند میتوانند بدون نیاز به دانش یا مهارت خاص در پروژه های در حال اجرا مشارکت کرده و در سود حاصل شریک شوند."])}
  }
}