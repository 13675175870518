import {createApp} from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import BootstrapVue3 from "bootstrap-vue-3";
import {isRtl,supportedLocales,dir,lang} from "@/assets/js/main";

if (isRtl) {
    require('bootstrap/dist/css/bootstrap.rtl.min.css')
    require('@/assets/css/style-rtl.css')
} else {
    require('bootstrap/dist/css/bootstrap.min.css')
    require('@/assets/css/style.css')
}
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import '@/assets/css/main.css'
import '@/assets/css/library/animate.min.css'

import '@mdi/font/css/materialdesignicons.min.css'

const app = createApp(App);

app.use(i18n);
app.use(store);
app.use(router);
app.use(BootstrapVue3);
app.config.globalProperties.supportedLocales = supportedLocales;
app.config.globalProperties.isRtl = isRtl;
app.config.globalProperties.lang = lang;
app.config.globalProperties.direction = dir;
app.mount('#app');
