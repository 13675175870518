import {createRouter, createWebHistory} from 'vue-router'
import i18n from '../i18n'
import {supportedLocales} from "@/assets/js/main";

function load(component) {
  return () => import(`@/views/${component}.vue`)
}


const routes = [
  {
    path: '/',
    redirect: `/${localStorage.getItem('language') || 'fa'}#home`,
  },
  {
    path: '/:locale',
    component: {
      template: '<router-view></router-view>',
    },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale;
      if (!supportedLocales.includes(locale)) {
        return next(`${localStorage.getItem('language') || 'fa'}${to.fullPath}`);
      }
      if (i18n.locale !== locale) {
        i18n.locale = locale;
        localStorage.setItem('language', locale)
      }
      return next()
    },

    children: [
      {
        path: '',
        name: 'home',
        component: load('HomeView'),
      },
    ],
  },
  // {
  //   path: '/:pathMatch(.*)*', // Unrecognized path automatically matches 404
  //   name: 'PageNotFound',
  //   component: load('404View'),
  // },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(() => {

})


router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})


export default router
